import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { releaseDataAPI } from "../../../redux/action/investor";
import { IMAGE_BASE_URL } from "../../../redux/constants";
import { WebRoutes } from "../../../routes";

const MemberMeeting  = ({ releaseDataAPI, releaseData }) => {
    useEffect(() => {
        releaseDataAPI(6);
      }, []);
      console.log(releaseData.data);
      return (
        <>
          <section className="cards terms" id="privacy-policy">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <img src="../assets/imgs/icon-back.svg" alt="icon" /> Home
                        </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                       Releases
                      </li>
                    </ol>
                  </nav>
    
                  <h4>Member Meeting</h4>
    
                  <div className="pills">
                    
                  <Link to={WebRoutes.MEMBER_MEETING}className="active">Member Meeting</Link>
                  <Link to={WebRoutes.BOARD_MEETING} >Board Meeting</Link>
                  </div>
                </div>
              </div>
    
              <div className="row stories">
                {releaseData &&
                  releaseData.data &&
                  releaseData.data.map((item, index) => (
                    <div className="col-md-4" key={index}>
                      <div className="story">
                        <div className="download">
                          <div className="file-type" onClick={() => window.open(IMAGE_BASE_URL + "/" + item.file_name, "_blank")}>
                            <img src="../assets/imgs/icon-pdf.svg" alt="icon" style={{ cursor: "pointer" }} />
                          </div>
    
                          <div className="file-link">
                            <a href={IMAGE_BASE_URL + "/" + item.file_name} download={IMAGE_BASE_URL + "/" + item.file_name} target="_blank">
                              <img src="../assets/imgs/icon-download.svg" alt="icon" />
                            </a>
                          </div>
                        </div>
    
                        <h5>
                          {item.file_title} 
                        </h5>
    
                       
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </section>
        </>
      );
    };
    
    const mapStateToProps = (state) => {
      const { InvestorReducer } = state;
      const { releaseData } = InvestorReducer;
      return {
        releaseData: InvestorReducer.releaseData,
      };
    };
    
    const mapDispatchToProps = (dispatch) => {
      return {
        releaseDataAPI: (data) => dispatch(releaseDataAPI(6)),
      };
    };
    
    export default connect(mapStateToProps, mapDispatchToProps)(MemberMeeting);