import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { connect } from "react-redux";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import { resetToast } from "../../redux/action/common";
import { emailSubscriptionApi } from "../../redux/action/enquiry";
import { categoryListApi } from "../../redux/action/category";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { socialLinkApi, metaTagsApi } from "../../redux/action/home";
import DocumentMeta from "react-document-meta";
import ReactDOM from "react-dom";
/*************** User Scripts *************/
// import "../static/user/vendor/jquery/jquery.min.js";
// import "../static/common/vendor/bootstrap/js/bootstrap.bundle.min.js";
// import "../static/user/vendor/owlCarousel/js/owl.carousel.min.js";
/*********************** */
const BaseDashboard = ({ toastType, toastData, resetToast, emailSubscriptionApi, categoryListApi, categoryData, socialLinkApi, socialLinkData, metaTagsApi, metaTagsData }) => {
         
    useEffect(() => {
        categoryListApi();
        socialLinkApi();
        metaTagsApi();
    }, []);
    // const [popupOpened, setPopupOpened] = useState(false);

    // useEffect(() => {
    //     if (!popupOpened) {
    //     const newPopup = window.open("", "_blank", "width=1300,height=700");

    //     if (newPopup) {
    //         newPopup.document.write(`
    //         <html>
    //             <head>
    //             <title>Important Notice</title>
    //             <style>
    //                 body { display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100vh; margin: 0; font-family: Arial, sans-serif; text-align: center; }
    //                 h2 { margin-bottom: 20px; }
    //                 button { font-size: 18px; padding: 10px 20px; cursor: pointer; background-color: #007bff; color: white; border: none; border-radius: 5px; }
    //                 button:hover { background-color: #0056b3; }
    //                 .logo-container {
    //                     display: flex;
    //                     justify-content: center;
    //                     align-items: center;
    //                     gap: 20px; /* Adds spacing between logos */
    //                     margin-bottom: 20px;
    //                 }

    //                 .logo-container img {
    //                     max-width: 150px; /* Adjust width as needed */
    //                     height: auto;
    //                 }
    //             </style>
    //             </head>
    //             <body>
    //                 <div class="logo-container">
    //                     <img src="../assets/imgs/MaheshTutorialslogo.svg" alt="mt-logo" />
    //                     <img src="../assets/imgs/logo-main.png" alt="mtedu-logo" />
    //                     <img src="../assets/imgs/Lakshyalogo.svg" alt="lakshya-logo" />
    //                 </div>
    //             <h2>Welcome to Mahesh Tutorials</h2>
    //             <p>Please click the button below to proceed to the LGSAT (Lakshya Grand Scholarship Admission Test).</p>
    //             <button id="openUrl">Go to LGSAT</button>
    //             <script>
    //                 document.getElementById("openUrl").addEventListener("click", function() {
    //                 window.open("https://lgsat.lakshyainstitute.com/?utm_source=MTeducareW&utm_medium=popup", "_blank");
    //                 window.close();
    //                 });
    //             </script>
    //             </body>
    //         </html>
    //         `);

    //         setPopupOpened(true); // Mark popup as opened
    //     }
    //     }
    // }, [popupOpened]); // Runs only if popupOpened changes

    useEffect(() => {
        if (toastType) {
            showToast();
            resetToast();
        }
    }, [toastType]);


    const showToast = () => {
        switch (toastType) {
            case "success":
                toast.success(toastData.message);
                break;
            case "error":
                toast.error(toastData.message);
                break;
            case "warning":
                toast.warning(toastData.message);
                break;
            default:
                break;
        }
    };
    const metaData =
        metaTagsData &&
        metaTagsData.data &&
        metaTagsData.data.filter((item) => {
            return window.location.pathname == item.page_name;
        });

    //   console.log(metaData);

    // const meta =
    //     metaData && metaData.map((data, index) => {
    //         <>
    //             <title>{data && data.mata_title}</title>
    //             <meta name={data && data.mata_keyboard} content={data && data.mata_description} />
    //             <link rel="canonical" href={data && data.canonical_tag} />

    //         </>
    //     })

    const meta = metaData &&
        metaData[0] && {
        title: metaData[0].title,
        description: metaData[0].mata_description,
        meta: {
            name: {
                keywords: metaData[0].mata_keyboard,
            },
        },
    };

    return (
        <div>
            <DocumentMeta {...meta}></DocumentMeta>
            <ToastContainer />
            <Header categoryListApi={categoryListApi} categoryData={categoryData} />
            <Outlet />           
            <Footer emailSubscriptionApi={emailSubscriptionApi} categoryData={categoryData} socialLinkData={socialLinkData} />
        </div>
    );
};

const mapStateToProps = (state) => {
    const { CommonReducer, CategoryReducer, HomeReducer } = state;
    const { categoryData } = CategoryReducer;

    return {
        toastData: CommonReducer.toastData,
        toastType: CommonReducer.toastType,
        categoryData: CategoryReducer.categoryData,
        socialLinkData: HomeReducer.socialLinkData,
        metaTagsData: HomeReducer.metaTagsData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetToast: () => dispatch(resetToast()),
        emailSubscriptionApi: (data) => dispatch(emailSubscriptionApi(data)),
        categoryListApi: () => dispatch(categoryListApi()),
        socialLinkApi: () => dispatch(socialLinkApi()),
        metaTagsApi: () => dispatch(metaTagsApi()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(BaseDashboard);
